import { useRouter } from 'next/router'
import cn from 'classnames'
import useCustomer from '@shopify/customer/use-customer'
import Icon from '@components/icons'
import { MULPASS_URL, I18N_STORE_DOMAIN } from '@shopify/const'
import { useEffect, useState } from 'react'
import { ButtonV2 } from '@components/ui'
import { motion, AnimatePresence } from 'framer-motion'
import { easingTransitions } from '@lib/utils/animate'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import s from './UserNav.module.css'

const UserNavChg = ({ className, onMouseEnter, onClick }) => {
  return (
    <div className={cn('relative flex h-full items-center', className)}>
      <div className="'relative [&:active]:outline-none' flex h-full cursor-pointer items-center text-primary outline-none duration-100 ease-in-out [&:focus]:outline-none">
        <button
          tag="Auto-10000111"
          id="userIcon"
          aria-label="account"
          className={
            'flex items-center justify-center rounded-full [&_svg]:hover:fill-brand-color'
          }
          onMouseEnter={onMouseEnter}
          onClick={onClick}
        >
          <Icon
            iconKey={'user'}
            className="h-[22px] w-[22px] fill-[#000] transition-all l:h-[22px] l:w-[22px] l-xl:h-[18px] l-xl:w-[18px]"
          ></Icon>
        </button>
      </div>
    </div>
  )
}

export default UserNavChg

export const User = ({
  showUser,
  showUserBoard,
  userPopLeft,
  data,
  preRender,
  onRegistrations,
  registrationsSettings,
}) => {
  return (
    <AnimatePresence>
      {userPopLeft && showUserBoard && (
        <motion.div
          style={{ left: userPopLeft }}
          className="absolute top-[60px] z-[50] flex w-[230px] flex-col items-center border border-[#E2E2E2] bg-white md:right-[24px] md:top-[51px] md:z-[99]"
        >
          <div className={s.UserContentBox}>
            <motion.div
              className={cn(
                'flex w-full flex-col items-center justify-center overflow-hidden px-[32px] py-[12px] pt-[24px]'
              )}
              initial={{ height: '0' }}
              exit={{ height: '0' }}
              animate={{ height: 'auto' }}
              transition={{
                duration: 0.2,
                ease: easingTransitions?.easeInOutQuart,
              }}
            >
              <UserContent
                data={data}
                preRender={preRender}
                onRegistrations={onRegistrations}
                registrationsSettings={registrationsSettings}
              />
            </motion.div>
          </div>
        </motion.div>
      )}
      {showUser && (
        <UserContent
          data={data}
          preRender={preRender}
          onRegistrations={onRegistrations}
          registrationsSettings={registrationsSettings}
        />
      )}
    </AnimatePresence>
  )
}

const UserContent = ({
  data,
  preRender,
  onRegistrations,
  registrationsSettings,
}) => {
  const { locale } = useRouter()
  const { data: customer } = useCustomer()
  const app = I18N_STORE_DOMAIN[locale].split('.').shift()
  const [origin, setOrigin] = useState(30)

  useEffect(() => {
    setOrigin(
      encodeURIComponent(window.location.origin + window.location.pathname)
    )
  }, [])

  return (
    <div className="w-full overflow-hidden">
      <div
        className={cn(
          'border-[#1D1D1F]-500/100 mb-[24px] flex w-full items-center gap-[8px] border-b pb-[12px]',
          !customer && 'min-md:hidden'
        )}
      >
        <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#fff] min-md:hidden">
          <Icon
            iconKey={'user'}
            className="h-[20px] w-[20px] fill-[#1D1D1F]"
          ></Icon>
        </div>
        <p className="break-all text-[#16px] font-[600] leading-[1.4] text-[#1D1D1F]">
          {customer
            ? data?.loginAfter?.replace('$name', customer?.email || '')
            : data?.loginBefore}
        </p>
      </div>

      {customer ? (
        <div className="flex w-full flex-col gap-[24px] text-[#16px] font-[500] leading-[1.4] text-[#1D1D1F]">
          {data?.account && (
            <a
              className="transition-all duration-[0.4s] [&:hover]:text-anker-color"
              href={`${MULPASS_URL}/account/?app=${I18N_STORE_DOMAIN[locale]
                .split('.')
                .shift()}`}
              aria-label="account"
              tag="Auto-100001110"
              dangerouslySetInnerHTML={{ __html: data?.account }}
            ></a>
          )}
          {data?.orders && (
            <a
              className="transition-all duration-[0.4s] [&:hover]:text-anker-color"
              href={`${MULPASS_URL}/account/orders?app=${I18N_STORE_DOMAIN[
                locale
              ]
                .split('.')
                .shift()}`}
              aria-label="account"
              dangerouslySetInnerHTML={{ __html: data?.orders }}
            ></a>
          )}
        </div>
      ) : (
        <>
          <div>
            <p
              className="text-[#16px] font-[600] leading-[1.4] text-[#1D1D1F]"
              dangerouslySetInnerHTML={{ __html: data?.title }}
            />
            <ul className="mt-[8px] flex flex-col gap-[4px] text-[#16px] font-[500] leading-[1.4] text-[#1D1D1F]">
              {data?.points?.map((item, index) => (
                <li key={index} className="flex gap-[6px]">
                  <Icon
                    iconKey={'success'}
                    className="h-[16px] w-[16px] fill-[#1D1D1F]"
                  />
                  <span
                    className="flex-1"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-[24px] flex flex-col gap-[4px] md:flex-row md:gap-[12px]">
            <ButtonV2
              tag="Auto-100001111"
              className="!h-[40px] !min-w-0 flex-1 !text-[14px] !leading-[40px]"
            >
              <a
                href={
                  registrationsSettings
                    ? null
                    : `${MULPASS_URL}/?app=${app}&ref=navimenu&source=${origin}${
                        preRender?.userRedirectBack ? `&redirect=${origin}` : ''
                      }`
                }
                onClick={(e) => {
                  registrationsSettings && onRegistrations(e, 'signIn')
                  pageGTMEvent({
                    event: 'ga4Event',
                    event_name: 'entry_for_register',
                    event_parameters: {
                      page_group: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                      button_name: data.signIn, //传按钮文案，如sign in或者sign up
                      position: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                    },
                  })
                }}
                dangerouslySetInnerHTML={{ __html: data?.signIn }}
              ></a>
            </ButtonV2>
            <ButtonV2
              tag="Auto-100001112"
              className={cn(s.signBtn)}
              variant="brandBorder"
            >
              <a
                href={
                  registrationsSettings
                    ? null
                    : `${MULPASS_URL}/?tab=register&app=${app}&ref=navimenu&source=${origin}${
                        preRender?.userRedirectBack ? `&redirect=${origin}` : ''
                      }`
                }
                onClick={(e) => {
                  registrationsSettings && onRegistrations(e, 'signUp')
                  pageGTMEvent({
                    event: 'ga4Event',
                    event_name: 'entry_for_register',
                    event_parameters: {
                      page_group: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                      button_name: data.signUp, //传按钮文案，如sign in或者sign up
                      position: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                    },
                  })
                }}
                dangerouslySetInnerHTML={{ __html: data?.signUp }}
              ></a>
            </ButtonV2>
          </div>
        </>
      )}
    </div>
  )
}
