import cn from 'classnames'
import Icon from '@components/icons'
import s from './Header.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const LiveComponent = ({ hasLiveContent }) => {
  const { hasLive = false, url, target } = hasLiveContent || {}
  const { setRelativeLink } = useRelativeLink()
  return (
    <div className={cn(s.livePartWrap)}>
      {hasLiveContent ? (
        <a
          href={setRelativeLink({ link: url })}
          target={target || '_self'}
          className="flex h-full items-center justify-center"
        >
          <span
            className={cn('relative block', s.live, { ['hidden']: hasLive })}
          >
            <Icon iconKey="live" />
          </span>
          <span className={cn('relative hidden', { ['!block']: hasLive })}>
            <Icon iconKey="liveBg" />
            <i className={cn(s.linePart, s.line_1)}></i>
            <i className={cn(s.linePart, s.line_2)}></i>
            <i className={cn(s.linePart, s.line_3)}></i>
          </span>
        </a>
      ) : null}

      <Icon
        iconKey="navMenuCountries"
        className={cn(
          'mr-[4px] hidden h-[20px] w-[20px] cursor-pointer outline-none min-l:h-[18px] min-l:w-[18px]',
          s.icon
        )}
        onClick={() =>
          setCountryModal({
            visible: true,
            position: 'navimenu',
          })
        }
      />
    </div>
  )
}

export default LiveComponent
