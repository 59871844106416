import { pageGTMEvent } from '@lib/utils/thirdparty'

export const onSearchClick = () => {
  pageGTMEvent({
    event: 'uaEvent',
    eventCategory: 'top_function',
    eventAction: '',
    eventLabel: 'Search',
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'top_function',
    event_parameters: {
      page_group: '',
      button_name: 'Search',
      position: '',
    },
  })
}
