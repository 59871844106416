import cn from 'classnames'
import Icon from '@components/icons'

const Ankerlogo = ({ pageType, rootDark, locale, s }) => {
  return (
    <>
      {(pageType === 'powerStation' || pageType === 'hes') && (
        <>
          <Icon
            iconKey="anker"
            width="82"
            className={cn({
              [s.rootDarkLogo]: rootDark,
              [s.noRootDarkLogo]: !rootDark,
            })}
          />
          <Icon
            iconKey="power"
            animate={rootDark ? 'dark' : 'light'}
            className={cn('ml-1 w-[72px]', {
              [s.rootDarkLogo]: rootDark,
              [s.noRootDarkLogo]: !rootDark,
            })}
          />
        </>
      )}
      {pageType !== 'powerStation' && pageType !== 'hes' && (
        <>
          <Icon
            isMobile
            iconKey="ankerslogen"
            className={cn('hidden h-[22px] l:block', s.ankerLogo, {
              [s.rootDarkLogo]: rootDark,
              [s.noRootDarkLogo]: !rootDark,
            })}
          />
          <Icon
            isMobile
            iconKey="ankerslogenLong"
            className={cn('h-[22px] l:hidden', s.ankerLogo, {
              [s.rootDarkLogo]: rootDark,
              [s.noRootDarkLogo]: !rootDark,
            })}
          />
        </>
      )}
    </>
  )
}

export default Ankerlogo
