import cn from 'classnames'
import dynamic from 'next/dynamic'
import { useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { appendRefParameter } from '@lib/utils/tools'
import Icon from '@components/icons'
import useCustomer from '@shopify/customer/use-customer'
import { Picture, ButtonV2, LoadingDots } from '@components/ui'
import { easingTransitions } from '@lib/utils/animate'

import s from './Header.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { SearchBoard } from '@components/common/Searchbar/SearchBoard'

const Loading = () => (
  <div className="flex h-80 w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)
const dynamicProps = {
  loading: Loading,
}
const SceneBanner = dynamic(() => import('./SceneBanner'), {
  ...dynamicProps,
})
const NewExplore = dynamic(() => import('./Explore'), {
  ...dynamicProps,
})
const Installer = dynamic(() => import('./Installer'), {
  ...dynamicProps,
})

const UnfoldWingsPC = ({
  pageType,
  shopCommon,
  lang,
  categories,
  dropDownPosition,
  currentDownBoxContent,
  currentSmallBoxContent,
  onMouseLeaveHidden,
  setAnimate,
  showSearchBoard,
  setShowSearchBoard,
  navSearch,
}) => {
  const { setRelativeLink } = useRelativeLink()

  const { data: customer } = useCustomer()
  const [collectionHover, setCollectionHover] = useState(0)

  useEffect(() => {
    setCollectionHover(0)
  }, [currentDownBoxContent])

  const currentCollectionHover = useMemo(() => {
    return (
      currentDownBoxContent && currentDownBoxContent?.content?.[collectionHover]
    )
  }, [currentDownBoxContent, collectionHover, categories])

  const currentCollectionHoverHeight = useMemo(() => {
    if (!currentSmallBoxContent?.textList?.length > 0) return 'auto'
    if (currentSmallBoxContent?.imgTitle?.length > 0)
      return 100 + currentSmallBoxContent?.textList?.length * 40
    return 50 + currentSmallBoxContent?.textList?.length * 40
  }, [currentSmallBoxContent])

  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  const heightMap = {
    newCollections: 'auto',
    sceneBanner: 'auto',
    collectionByHandle: 'auto',
    gather: 'auto',
  }

  const smallContentIsFull = useMemo(() => {
    return ['explore', 'installer']?.includes(currentSmallBoxContent?.mode)
  }, [currentSmallBoxContent])

  return (
    <>
      <motion.div
        key="dropDownBox-nav-root"
        className={cn(s.dropDownBox, showSearchBoard ? 'z-[50]' : 'z-[49]')}
        animate={setAnimate}
        custom={[dropDownPosition, currentSmallBoxContent]}
        variants={{
          initSmall: ([dropDownPosition, currentSmallBoxContent]) => ({
            left: smallContentIsFull ? 0 : dropDownPosition || 0,
            width: smallContentIsFull
              ? '100vw'
              : currentSmallBoxContent?.imgTitle
              ? 500
              : 250,
            height: currentCollectionHoverHeight,
            opacity: 1,
            transition: {
              ease: easingTransitions?.easeOutQuart,
              duration: 0.3,
              width: {
                duration: 0,
              },
              left: {
                duration: 0,
              },
            },
          }),
          openSmall: ([dropDownPosition, currentSmallBoxContent]) => ({
            left: smallContentIsFull ? 0 : dropDownPosition || 0,
            width: smallContentIsFull
              ? '100vw'
              : currentSmallBoxContent?.imgTitle
              ? 500
              : 250,
            height: currentCollectionHoverHeight,
            opacity: 1,
            transition: {
              ease: easingTransitions?.easeInOutQuart,
              duration: 0.3,
            },
          }),
          open: {
            left: 0,
            width: '100vw',
            height: showSearchBoard
              ? 470
              : heightMap[currentDownBoxContent?.mode] || 380,
            opacity: 1,
            top: showSearchBoard && '60px',
            background: showSearchBoard && '#F7F8FA',
            transition: {
              duration: 0.3,
              ease: easingTransitions?.easeInOutQuart,
            },
          },
          closed: {
            width: '100vw',
            left: 0,
            height: 0,
            opacity: 0,
            transition: {
              ease: easingTransitions?.easeInQuart,
              duration: 0.3,
              delay: 0.1,
              width: {
                delay: 0.4,
                duration: 0,
              },
              left: {
                delay: 0.4,
                duration: 0,
              },
            },
          },
        }}
      >
        <div
          className={cn(s.dropDownBoxContent, 'layer_max', {
            [s.dropDownBoxFullContent]:
              !!currentSmallBoxContent || smallContentIsFull,
            [s.dropDownBoxContentOpen]:
              currentDownBoxContent || currentSmallBoxContent,
          })}
        >
          {/* <AnimatePresence> */}
          {showSearchBoard && (
            <SearchBoard
              data={navSearch}
              onClose={() => setShowSearchBoard(false)}
            />
          )}
          {/* PC端 展开后 -  w-full-Box */}
          {currentDownBoxContent && (
            <motion.div
              key={`${currentDownBoxContent?.mode}-${currentDownBoxContent?.text}`}
              className={cn(s.content, 'content', {
                [s.gridContent]: [
                  'collections',
                  'newCollections',
                  'sceneBanner',
                ]?.includes(currentDownBoxContent?.mode),
                ['!grid-cols-14']: ['newCollections', 'sceneBanner']?.includes(
                  currentDownBoxContent?.mode
                ),
                [s.compactContent]: currentDownBoxContent?.content?.length > 8,
                [s.collectionByHandle]:
                  currentDownBoxContent?.mode === 'collectionByHandle',
                ['!pt-[15px]']: currentDownBoxContent?.title,
              })}
            >
              <CurrentDownBoxContent
                s={s}
                currentDownBoxContent={currentDownBoxContent}
                collectionHover={collectionHover}
                setCollectionHover={setCollectionHover}
                currentCollectionHover={currentCollectionHover}
                shopCommon={shopCommon}
                lang={lang}
              />
            </motion.div>
          )}

          {smallContentIsFull && (
            <>
              {currentSmallBoxContent?.mode === 'explore' && (
                <NewExplore
                  handleLiveChat={handleLiveChat}
                  currentSmallBoxContent={currentSmallBoxContent}
                  s={s}
                />
              )}
              {currentSmallBoxContent?.mode === 'installer' && (
                <Installer
                  currentSmallBoxContent={currentSmallBoxContent}
                  pageType={pageType}
                  s={s}
                />
              )}
            </>
          )}

          {/* PC端 展开后 -  w-500-Box */}
          {currentSmallBoxContent &&
            !smallContentIsFull &&
            currentSmallBoxContent?.imgList?.length > 0 &&
            currentSmallBoxContent?.textList?.length > 0 && (
              <motion.div
                key={`dropDownMiddleContent-${currentSmallBoxContent?.text}`}
                className={s.dropDownMiddleContent}
                initial={{
                  opacity: 0,
                  x: -30,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    ease: easingTransitions?.easeInOutQuart,
                    duration: 0.3,
                    delay: 0.15,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    ease: easingTransitions?.easeInQuart,
                    duration: 0.3,
                  },
                }}
              >
                <div className={s.dropLeftBox}>
                  <p
                    className={s.dropSubBoxTitle}
                    dangerouslySetInnerHTML={{
                      __html: currentSmallBoxContent?.imgTitle,
                    }}
                  />
                  <div className={s.dropSubBoxList}>
                    {currentSmallBoxContent?.imgList?.map((item, g) => {
                      return (
                        <a
                          className={s.linkToBtn}
                          href={`${setRelativeLink({
                            link:
                              customer && item?.login_url
                                ? item?.login_url
                                : item?.url,
                          })}?ref=naviMenu`}
                          key={`dropSubBoxList${item?.img}-${g}`}
                        >
                          <Picture source={item?.img} alt="" />
                        </a>
                      )
                    })}
                  </div>
                </div>
                <div className={s.dropRightBox}>
                  <p
                    className={s.dropSubBoxTitle}
                    dangerouslySetInnerHTML={{
                      __html: currentSmallBoxContent?.textTitle,
                    }}
                  />
                  <div className={s.dropSubBoxList}>
                    {currentSmallBoxContent?.textList?.map((item, g) => {
                      return (
                        <a
                          key={`dropSubBoxList-${item?.text}-${g}`}
                          className={s.linkToBtn}
                          href={`${setRelativeLink({
                            link:
                              customer && item?.login_url
                                ? item?.login_url
                                : item?.url,
                          })}?ref=naviMenu`}
                        >
                          {item?.text}
                        </a>
                      )
                    })}
                  </div>
                </div>
              </motion.div>
            )}

          {/* PC端 展开后 -  w-250-Box */}
          {currentSmallBoxContent &&
            !smallContentIsFull &&
            !currentSmallBoxContent?.imgTitle && (
              <motion.div
                key={`dropDownSmallContent-${currentSmallBoxContent?.text}`}
                className={s.dropDownSmallContent}
                initial={{
                  opacity: 0,
                  x: -30,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    ease: easingTransitions?.easeInOutQuart,
                    duration: 0.3,
                    delay: 0.15,
                  },
                }}
                exit={{
                  opacity: 0,
                  transition: {
                    ease: easingTransitions?.easeInQuart,
                    duration: 0.3,
                  },
                }}
              >
                {currentSmallBoxContent?.textList?.map((item, g) => {
                  return (
                    <a
                      dangerouslySetInnerHTML={{
                        __html: item?.text,
                      }}
                      key={`dropDownSmallContenta-${item?.url}-${g}`}
                      className={s.linkToBtn}
                      onClick={(e) => {
                        item?.url === 'online-message' && handleLiveChat(e)
                      }}
                      href={`${setRelativeLink({
                        link:
                          customer && item?.login_url
                            ? item?.login_url
                            : item?.url,
                      })}?ref=naviMenu`}
                    ></a>
                  )
                })}
              </motion.div>
            )}
          {/* </AnimatePresence> */}
        </div>
      </motion.div>

      <AnimatePresence mode="popLayout">
        {setAnimate() !== 'closed' && (
          <motion.div
            key="dropDownBox-nav-mask"
            className={s.mask}
            transition={{
              duration: 0.3,
              ease: easingTransitions?.easeOutQuart,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onMouseEnter={onMouseLeaveHidden}
            onClick={() => setShowSearchBoard(false)}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default UnfoldWingsPC

const CurrentDownBoxContent = ({
  s,
  currentDownBoxContent,
  collectionHover,
  setCollectionHover,
  currentCollectionHover,
  shopCommon,
  lang,
}) => {
  const router = useRouter()
  const { locale } = router
  const { setRelativeLink } = useRelativeLink()

  switch (currentDownBoxContent?.mode) {
    case 'collectionByHandle':
      return (
        <>
          <div className={s.selectContent}>
            <div className="selectContentH flex h-full flex-col justify-between">
              <ul className={s.selectList}>
                {currentDownBoxContent?.content?.map((item, index) => {
                  return (
                    <li
                      key={`collectionsLi-${item?.name}${index}`}
                      className={cn(s.option, {
                        [s.active]: collectionHover === index,
                      })}
                    >
                      <button
                        onMouseEnter={() => {
                          setCollectionHover(index)
                        }}
                      >
                        <span>{item?.name}</span>
                      </button>
                    </li>
                  )
                })}
              </ul>
              <div className={s.linksList}>
                <div className={s.bottomBar}>
                  <ul className={s.list}>
                    {currentDownBoxContent?.otherLinks?.map((item, index) => (
                      <li
                        key={`collectionList-${collectionHover}-${item}-${index}`}
                      >
                        <a
                          href={`${setRelativeLink({
                            link: item?.to,
                          })}?ref=naviMenu`}
                          rel="noopener noreferrer"
                          className={cn(s.linkTo)}
                        >
                          {item?.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {currentDownBoxContent?.shopAllLink && (
                    <ButtonV2
                      className={s.bottomBarBtn}
                      animateKey="collectionHoverBtn"
                    >
                      <a
                        href={`${setRelativeLink({
                          link: currentDownBoxContent?.shopAllLink,
                        })}?ref=naviMenu`}
                        className="block"
                      >
                        {currentCollectionHover?.jumpCollection
                          ? currentCollectionHover.jumpCollection
                          : lang?.seeAll
                              ?.replace(
                                '%collection%',
                                currentDownBoxContent?.text
                              )
                              ?.toUpperCase()}
                      </a>
                    </ButtonV2>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={s.optionContent}>
            {lang?.seeCollection &&
              currentCollectionHover?.jumpCollection !== 'hidden' && (
                <AnimatePresence mode="popLayout">
                  <motion.div className="mb-[16px] flex items-center text-[14px] font-[500] leading-[1.4] text-[#777]">
                    <span>
                      {lang?.seeCollection
                        ?.replace('%collection1%', currentDownBoxContent?.text)
                        ?.replace(
                          '%collection2%',
                          currentCollectionHover?.name
                        )}
                    </span>
                    <a
                      href={`${setRelativeLink({
                        link: currentCollectionHover?.path,
                      })}?ref=naviMenu`}
                      rel="noopener noreferrer"
                      className="ml-[12px] flex items-center font-[500] text-[#1D1D1F] transition-all duration-[0.4s] [&:hover]:text-[#0AF] [&:hover_path]:stroke-[#0AF]"
                    >
                      <span>{lang?.viewAll}</span>
                      <Icon
                        className="pb-[2px]"
                        iconKey="arrowRight"
                        width={24}
                        height={24}
                      ></Icon>
                    </a>
                  </motion.div>
                </AnimatePresence>
              )}
            {currentCollectionHover?.starCard && (
              <div className={s.navStarCardList}>
                <AnimatePresence mode="popLayout">
                  {currentCollectionHover?.productList?.map((item, index) => {
                    return (
                      <motion.a
                        href={`${setRelativeLink({
                          link: item?.url,
                        })}?ref=naviMenu`}
                        className={s.navStarCardBox}
                        key={`starCard-${collectionHover}-${item?.name}-${index}`}
                      >
                        <Picture
                          className="h-full w-full object-cover"
                          source={`${item?.img}, ${item?.img1024} 1024, ${item?.img768} 768`}
                        />
                        <div
                          className={cn(s.navStarCardContent, {
                            [s.drak]: item?.theme === 'drak',
                          })}
                        >
                          <p className={s.navStarCardTitle}>{item?.title}</p>
                          <p className={s.navStarCardSub}>{item?.subtitle}</p>
                          <p className={s.navStarCardLearn}>
                            {item?.learnmore}
                          </p>
                        </div>
                      </motion.a>
                    )
                  })}
                </AnimatePresence>
              </div>
            )}
            {currentCollectionHover?.starProducts && (
              <div
                className={cn(s.navStarList, {
                  [s.navStarListMore]:
                    currentCollectionHover?.productList?.length > 1,
                })}
              >
                <AnimatePresence mode="popLayout">
                  {currentCollectionHover?.productList?.map((item, index) => {
                    const smallBox =
                      currentCollectionHover?.productList?.length > 1
                    const url = appendRefParameter({
                      url: item?.url,
                      refValue: 'naviMenu',
                    })
                    return (
                      <motion.a
                        href={setRelativeLink({ link: url })}
                        className={cn(s.productBox, {
                          [s.productSmallBox]: smallBox,
                        })}
                        key={`starProducts-${collectionHover}-${item?.name}-${index}`}
                      >
                        <div className={s.detail}>
                          <div className={s.llx_box}>
                            <p
                              className={s.tag}
                              dangerouslySetInnerHTML={{
                                __html: item?.tag,
                              }}
                            />
                            <p
                              className={s.name}
                              dangerouslySetInnerHTML={{
                                __html: item?.name,
                              }}
                            />
                            <div className={s.llx_productImg}>
                              <Picture source={item?.img} alt="" />
                            </div>
                          </div>
                          <p
                            className={cn(s.description)}
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          />
                          <p
                            className={s.learnMore}
                            dangerouslySetInnerHTML={{
                              __html: item?.learnMore,
                            }}
                          />
                        </div>
                        <div className={s.productImg}>
                          <Picture source={item?.img} alt="" />
                        </div>
                        <ul className={s.highlights}>
                          {item?.highlights?.length
                            ? item?.highlights?.split('||')?.map((txt, i) => {
                                return (
                                  <li
                                    key={`${txt}-${i}`}
                                    className={s.hightLight}
                                  >
                                    <CheckCircleIcon
                                      className="mr-2 flex-initial"
                                      width="24"
                                      stroke="#ff9128"
                                    />
                                    <span>{txt}</span>
                                  </li>
                                )
                              })
                            : ''}
                        </ul>
                      </motion.a>
                    )
                  })}
                  <div className={s.otherLinksBox}>
                    {currentCollectionHover?.otherLinks?.map((item, index) => {
                      return (
                        <motion.a
                          href={setRelativeLink({ link: item?.to })}
                          className={s.HOtherLinks}
                          key={`HOtherLinks-${collectionHover}-${index}`}
                          dangerouslySetInnerHTML={{
                            __html: item?.label,
                          }}
                        />
                      )
                    })}
                  </div>
                </AnimatePresence>
              </div>
            )}
            {!currentCollectionHover?.starProducts &&
              !currentCollectionHover?.starCard && (
                <>
                  <div className={s.productsListBox}>
                    <ul className={s.productsList}>
                      <AnimatePresence mode="popLayout">
                        {currentCollectionHover?.productList?.map(
                          (item, index) => {
                            const tag = item.tags?.find(
                              (tag) =>
                                tag in (shopCommon.tagToDescription || {})
                            )
                            const tagTxt =
                              shopCommon?.tagToDescription?.[tag]?.label ||
                              shopCommon?.tagToDescription?.[tag] ||
                              ''
                            const tags =
                              item?.metafields?.productTags?.split(',') || []
                            if (index > 5) return
                            return (
                              <motion.li
                                className={s.product}
                                key={`productList-${collectionHover}-${item?.name}-${index}`}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={`${
                                    locale === 'us' ? '' : `/${locale}`
                                  }/products${item?.path}?ref=naviMenu`}
                                  className={s.producta}
                                >
                                  <div className={s.imgBox}>
                                    <Picture
                                      source={item?.images?.[0]?.url}
                                      alt={item?.name}
                                      quality={0.6}
                                    />
                                  </div>
                                  <div
                                    className={cn(
                                      'flex h-full flex-1 flex-col justify-between'
                                    )}
                                  >
                                    <div className="">
                                      <p
                                        className={s.tag}
                                        dangerouslySetInnerHTML={{
                                          __html: tagTxt?.toUpperCase(),
                                        }}
                                      />
                                      <p
                                        className={s.name}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.name,
                                        }}
                                      />
                                    </div>
                                    {tags?.length > 0 && (
                                      <div className="flex gap-[4px]">
                                        {tags?.map((tag, i) => (
                                          <span
                                            key={tag + i}
                                            className="bg-[#EEEEEF] px-[4px] py-[2px] text-[12px] font-[500] leading-[1.4] text-[#1D1D1F]"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </a>
                              </motion.li>
                            )
                          }
                        )}
                      </AnimatePresence>
                    </ul>
                  </div>
                </>
              )}
          </div>
        </>
      )
    case 'collections':
      return (
        <>
          <ul className={s.selectList}>
            {currentDownBoxContent?.content?.map((item, index) => {
              return (
                <li
                  key={`collectionsLi-${item?.name}${index}`}
                  className={cn(s.option, {
                    [s.active]: collectionHover === index,
                  })}
                >
                  <button
                    onMouseEnter={() => {
                      setCollectionHover(index)
                    }}
                  >
                    <span>{item?.name}</span>
                    <ChevronRightIcon className={s.iconArrow} />
                  </button>
                </li>
              )
            })}
          </ul>
          <div className={s.optionContent}>
            {currentCollectionHover?.starCard && (
              <div className={s.navStarCardList}>
                <AnimatePresence mode="popLayout">
                  {currentCollectionHover?.productList?.map((item, index) => {
                    return (
                      <motion.a
                        href={`${setRelativeLink({
                          link: item?.url,
                        })}?ref=naviMenu`}
                        className={s.navStarCardBox}
                        key={`starCard-${collectionHover}-${item?.name}-${index}`}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={{
                          initial: {
                            opacity: 0,
                            // x: -20,
                          },
                          animate: {
                            opacity: 1,
                            x: 0,
                            transition: {
                              ease: easingTransitions?.easeInOutQuart,
                              duration: 0.3,
                              delay: 0.2 + index * 0.1,
                            },
                          },
                          exit: {
                            opacity: 0,
                            transition: {
                              ease: easingTransitions?.easeInQuart,
                              duration: 0.3,
                            },
                          },
                        }}
                      >
                        <Picture
                          className="h-full w-full object-cover"
                          source={`${item?.img}, ${item?.img1024} 1024, ${item?.img768} 768`}
                        />
                        <div
                          className={cn(s.navStarCardContent, {
                            [s.drak]: item?.theme === 'drak',
                          })}
                        >
                          <p className={s.navStarCardTitle}>{item?.title}</p>
                          <p className={s.navStarCardSub}>{item?.subtitle}</p>
                          <p className={s.navStarCardLearn}>
                            {item?.learnmore}
                          </p>
                        </div>
                      </motion.a>
                    )
                  })}
                </AnimatePresence>
              </div>
            )}
            {currentCollectionHover?.starProducts && (
              <div
                className={cn(s.navStarList, {
                  [s.navStarListMore]:
                    currentCollectionHover?.productList?.length > 1,
                })}
              >
                <AnimatePresence mode="popLayout">
                  {currentCollectionHover?.productList?.map((item, index) => {
                    const smallBox =
                      currentCollectionHover?.productList?.length > 1
                    const url = appendRefParameter({
                      url: item?.url,
                      refValue: 'naviMenu',
                    })
                    return (
                      <motion.a
                        href={setRelativeLink({ link: url })}
                        className={cn(s.productBox, {
                          [s.productSmallBox]: smallBox,
                        })}
                        key={`starProducts-${collectionHover}-${item?.name}-${index}`}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={{
                          initial: {
                            opacity: 0,
                            // x: -20,
                          },
                          animate: {
                            opacity: 1,
                            x: 0,
                            transition: {
                              ease: easingTransitions?.easeInOutQuart,
                              duration: 0.3,
                              delay: 0.2 + index * 0.1,
                            },
                          },
                          exit: {
                            opacity: 0,
                            transition: {
                              ease: easingTransitions?.easeInQuart,
                              duration: 0.3,
                            },
                          },
                        }}
                      >
                        <div className={s.detail}>
                          <div className={s.llx_box}>
                            <p
                              className={s.tag}
                              dangerouslySetInnerHTML={{
                                __html: item?.tag,
                              }}
                            />
                            <p
                              className={s.name}
                              dangerouslySetInnerHTML={{
                                __html: item?.name,
                              }}
                            />
                            <div className={s.llx_productImg}>
                              <Picture source={item?.img} alt="" />
                            </div>
                          </div>
                          <p
                            className={cn(s.description)}
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          />
                          <p
                            className={s.learnMore}
                            dangerouslySetInnerHTML={{
                              __html: item?.learnMore,
                            }}
                          />
                        </div>
                        <div className={s.productImg}>
                          <Picture source={item?.img} alt="" />
                        </div>
                        <ul className={s.highlights}>
                          {item?.highlights?.length
                            ? item?.highlights?.split('||')?.map((txt, i) => {
                                return (
                                  <li
                                    key={`${txt}-${i}`}
                                    className={s.hightLight}
                                  >
                                    <CheckCircleIcon
                                      className="mr-2 flex-initial"
                                      width="24"
                                      stroke="#ff9128"
                                    />
                                    <span>{txt}</span>
                                  </li>
                                )
                              })
                            : ''}
                        </ul>
                      </motion.a>
                    )
                  })}
                  <div className={s.otherLinksBox}>
                    {currentCollectionHover?.otherLinks?.map((item, index) => {
                      return (
                        <motion.a
                          href={setRelativeLink({ link: item?.to })}
                          className={s.HOtherLinks}
                          key={`HOtherLinks-${collectionHover}-${index}`}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          variants={{
                            initial: {
                              opacity: 0,
                              // x: -20,
                            },
                            animate: {
                              opacity: 1,
                              x: 0,
                              transition: {
                                ease: easingTransitions?.easeInOutQuart,
                                duration: 0.3,
                                delay: 0.2 + index * 0.1,
                              },
                            },
                            exit: {
                              opacity: 0,
                              transition: {
                                ease: easingTransitions?.easeInQuart,
                                duration: 0.3,
                              },
                            },
                          }}
                          dangerouslySetInnerHTML={{
                            __html: item?.label,
                          }}
                        />
                      )
                    })}
                  </div>
                </AnimatePresence>
              </div>
            )}
            {!currentCollectionHover?.starProducts &&
              !currentCollectionHover?.starCard && (
                <>
                  <div className={s.productsListBox}>
                    <ul className={s.productsList}>
                      <AnimatePresence mode="popLayout">
                        {currentCollectionHover?.productList?.map(
                          (item, index) => {
                            const tag = item.tags?.find(
                              (tag) =>
                                tag in (shopCommon.tagToDescription || {})
                            )
                            const tagTxt =
                              shopCommon?.tagToDescription?.[tag]?.label ||
                              shopCommon?.tagToDescription?.[tag] ||
                              ''
                            return (
                              <motion.li
                                className={s.product}
                                key={`productList-${collectionHover}-${item?.name}-${index}`}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                variants={{
                                  initial: {
                                    opacity: 0,
                                    // x: -20,
                                  },
                                  animate: {
                                    opacity: 1,
                                    x: 0,
                                    transition: {
                                      ease: easingTransitions?.easeInOutQuart,
                                      duration: 0.3,
                                      delay: 0.2 + index * 0.05,
                                    },
                                  },
                                  exit: {
                                    opacity: 0,
                                    display: 'none',
                                    transition: {
                                      ease: easingTransitions?.easeInQuart,
                                      duration: 0.3,
                                      display: {
                                        delay: 0.3,
                                      },
                                    },
                                  },
                                }}
                              >
                                <a
                                  rel="noopener noreferrer"
                                  href={`${
                                    locale === 'us' ? '' : `/${locale}`
                                  }/products${item?.path}?ref=naviMenu`}
                                  className={s.producta}
                                >
                                  <div className={s.imgBox}>
                                    <Picture
                                      source={item?.images?.[0]?.url}
                                      alt={item?.name}
                                      quality={0.6}
                                    />
                                  </div>
                                  <p
                                    className={s.name}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.name,
                                    }}
                                  />
                                  {tagTxt && (
                                    <p
                                      className={s.tag}
                                      dangerouslySetInnerHTML={{
                                        __html: tagTxt,
                                      }}
                                    />
                                  )}
                                </a>
                              </motion.li>
                            )
                          }
                        )}
                      </AnimatePresence>
                    </ul>
                  </div>
                  {currentCollectionHover?.jumpCollection !== 'hidden' && (
                    <div className={s.bottomBar}>
                      <ul className={s.list}>
                        <AnimatePresence mode="popLayout">
                          {currentCollectionHover?.collectionList?.map(
                            (item, index) => {
                              return item?.name ? (
                                <motion.li
                                  key={`collectionList-${collectionHover}-${item}-${index}`}
                                  initial="initial"
                                  animate="animate"
                                  exit="exit"
                                  variants={{
                                    initial: {
                                      opacity: 0,
                                      // x: -20,
                                    },
                                    animate: {
                                      opacity: 1,
                                      x: 0,
                                      transition: {
                                        ease: easingTransitions?.easeInOutQuart,
                                        duration: 0.2,
                                        delay: 0.1 + index * 0.05,
                                      },
                                    },
                                    exit: {
                                      opacity: 0,
                                      transition: {
                                        ease: easingTransitions?.easeInQuart,
                                        duration: 0.2,
                                      },
                                    },
                                  }}
                                >
                                  <a
                                    href={`${
                                      locale === 'us' ? '' : `/${locale}`
                                    }${item?.path}?ref=naviMenu`}
                                    rel="noopener noreferrer"
                                    className={cn(s.linkTo, s.newLinkTo)}
                                  >
                                    {item?.name}
                                    <ChevronRightIcon
                                      className={s.linkToArrow}
                                    />
                                  </a>
                                </motion.li>
                              ) : (
                                ''
                              )
                            }
                          )}
                        </AnimatePresence>
                      </ul>

                      <ButtonV2
                        className={s.bottomBarBtn}
                        animateKey="collectionHoverBtn"
                      >
                        <a
                          href={
                            currentCollectionHover?.shopAllLink
                              ? setRelativeLink({
                                  link: currentCollectionHover?.shopAllLink,
                                })
                              : `${locale === 'us' ? '' : `/${locale}`}${
                                  currentCollectionHover?.path
                                }?ref=naviMenu`
                          }
                          className="block"
                        >
                          {currentCollectionHover?.jumpCollection
                            ? currentCollectionHover.jumpCollection
                            : lang?.seeAll?.replace(
                                '%collection%',
                                currentCollectionHover?.name
                              )}
                        </a>
                      </ButtonV2>
                    </div>
                  )}
                </>
              )}
          </div>
        </>
      )
    case 'navBanner':
      return (
        <div className="flex w-full flex-col">
          {currentDownBoxContent?.title && (
            <a
              className={s.solixTitle}
              href={`${setRelativeLink({
                link: currentDownBoxContent?.to,
              })}?ref=naviMenu`}
            >
              <span>{currentDownBoxContent?.title}</span>
              <ChevronRightIcon className={s.solixSvg} />
            </a>
          )}
          <div className="flex">
            {currentDownBoxContent?.content?.map((item, index) => {
              return (
                <a
                  key={`navBanner-${item?.title}-${index}`}
                  href={`${setRelativeLink({
                    link: item?.href,
                  })}?ref=naviMenu`}
                  rel="noopener noreferrer"
                  className={cn(s.navBanner, {
                    [s.firstBanner]: index === 0,
                  })}
                >
                  <div
                    className={cn(s.navBannerTxt, {
                      [s.modeDark]: item?.mode?.toLowerCase() === 'dark',
                    })}
                  >
                    <div>
                      <span
                        className={s.navBannerTitle}
                        dangerouslySetInnerHTML={{
                          __html: item?.title,
                        }}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.subtitle,
                        }}
                      />
                    </div>
                    <p
                      className={s.viewMore}
                      dangerouslySetInnerHTML={{
                        __html: item?.learnmore,
                      }}
                    />
                  </div>
                  <Picture className={s.bgImg} source={item?.bgImg} alt="" />
                </a>
              )
            })}
          </div>
        </div>
      )
    case 'gather':
      return (
        <div className="flex w-full items-start gap-[16px]">
          {currentDownBoxContent?.content?.map((item, index) => {
            if (item?.mode == 'row') {
              return (
                <div
                  key={index}
                  className="flex w-[calc((100%-48px)/4)] flex-col text-[#000]"
                >
                  {item?.title && (
                    <p className="mb-[12px] text-[16px] font-[600] leading-[1.4]">
                      {item?.title}
                    </p>
                  )}

                  <div className="flex flex-col gap-[12px]">
                    {item?.list?.map((child, childIndex) => (
                      <a
                        key={childIndex}
                        className="flex gap-[4px]"
                        href={`${setRelativeLink({
                          link: child?.href,
                        })}?ref=naviMenu`}
                      >
                        <p className="text-[14px] font-[500] leading-[1.4] text-[#777] hover:text-[#1D1D1F]">
                          {child?.title}
                        </p>
                        {child?.hotIcon && (
                          <p
                            className="text-[13px] font-[500]"
                            style={{
                              color: `${child?.hotIconColor || '#FF5C00'}`,
                            }}
                          >
                            {child?.hotIcon?.toUpperCase()}
                          </p>
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              )
            }

            if (item?.mode == 'image') {
              return (
                <div
                  key={index}
                  className="flex w-[calc((100%-48px)/4)] flex-col text-[#000]"
                >
                  <div className="flex flex-col gap-[15px]">
                    {item?.list?.map((child, childIndex) => (
                      <a
                        href={`${setRelativeLink({
                          link: child?.href,
                        })}?ref=naviMenu`}
                        key={childIndex}
                        className={cn(s.gatherBox, {
                          ['flex-col !items-start']: child?.img,
                        })}
                      >
                        <div className={s.imageWrap}>
                          <Picture
                            className={cn(
                              s.gatherImage,
                              'aspect-h-[247] aspect-w-[393]'
                            )}
                            source={child?.img}
                            alt={child?.alt || ''}
                          />
                        </div>

                        <div className="">
                          <div className="relative inline-block w-auto pr-[20px]">
                            <p className="line-clamp-1 text-[16px] leading-[1.4]">
                              {child?.title}
                            </p>
                            <ChevronRightIcon className="absolute right-0 top-1/2 block w-[12px] -translate-y-1/2 stroke-[3px] pb-[3px]" />
                          </div>
                          <p className="text-[14px] leading-[1.4] text-[#777]">
                            {child?.subtitle}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )
            }
          })}
        </div>
      )
    case 'sceneBanner':
      return (
        <SceneBanner
          s={s}
          currentDownBoxContent={currentDownBoxContent}
          collectionHover={collectionHover}
          setCollectionHover={setCollectionHover}
          currentCollectionHover={currentCollectionHover}
          shopCommon={shopCommon}
        ></SceneBanner>
      )
  }
}
